<template>
  <div class="container-fluid px-xl-4 py-4">
    <div class="car-list">
      <confirmation-dialog @close="dialogDelete = false" :dialog="dialogDelete" />

      <div class="hint" style="display: none;">
        <span class="hint--red">Click on
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="25" height="25" rx="8" fill="#004085" />
            <path
              d="M18.5 13.5H13.5V18.5C13.5 18.7652 13.3946 19.0196 13.2071 19.2071C13.0196 19.3946 12.7652 19.5 12.5 19.5C12.2348 19.5 11.9804 19.3946 11.7929 19.2071C11.6054 19.0196 11.5 18.7652 11.5 18.5V13.5H6.5C6.23478 13.5 5.98043 13.3946 5.79289 13.2071C5.60536 13.0196 5.5 12.7652 5.5 12.5C5.5 12.2348 5.60536 11.9804 5.79289 11.7929C5.98043 11.6054 6.23478 11.5 6.5 11.5H11.5V6.5C11.5 6.23478 11.6054 5.98043 11.7929 5.79289C11.9804 5.60536 12.2348 5.5 12.5 5.5C12.7652 5.5 13.0196 5.60536 13.2071 5.79289C13.3946 5.98043 13.5 6.23478 13.5 6.5V11.5H18.5C18.7652 11.5 19.0196 11.6054 19.2071 11.7929C19.3946 11.9804 19.5 12.2348 19.5 12.5C19.5 12.7652 19.3946 13.0196 19.2071 13.2071C19.0196 13.3946 18.7652 13.5 18.5 13.5Z"
              fill="white" />
          </svg>
          icon under the ACTIONS column to add parts to your inventory from any of the current stocked vehicles
          below. </span>
      </div>

      <div class="custom-table">
        <div class="search-container">
          <h5 class="total-car"></h5>

          <div class="s-c-right">


            <div class="search-group">
              <input placeholder="Search..." type="text" id="dataTableSearch" ref="dataTableSearch" />
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                  stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19 19L14.7 14.7" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
            <div class="d-flex gap-5 add-btn">
              <router-link class="btn custom-btn pattern-btn" class-active="" to="/part-settings"><span>Custom
                  Part's
                  List</span></router-link>
              <router-link class="btn custom-btn pattern-btn" class-active="" to="/add-vehicle"><span>Add
                  Vehicle</span></router-link>
            </div>
          </div>


        </div>
        <table id="example" class="display responsive nowrap" cellspacing="0" width="100%">
          <thead>
            <tr>
              <th class="desktop tablet-l tablet-p mobile-p mobile-l">Id</th>
              <th class="desktop">Date</th>
              <th class="desktop tablet-l tablet-p mobile-p mobile-l">
                Make/Model/Year
              </th>
              <th class="none">VIN</th>
              <th class="desktop tablet-l tablet-p">Cost ($)</th>
              <th class="desktop">Parts Cost ($)</th>
              <th class="none">Parts Count<br>Sold / Total</th>
              <th class="desktop">Stock No.</th>
              <th class="none">Description</th>
              <th class="desktop tablet-l tablet-p mobile-p mobile-l">Edit Car </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters, mapActions } from "vuex";
import { CARLIST } from "@/constants/apiUrls";
import { ITEMS_PER_PAGE } from "@/constants/common";
import { showFailure } from "../eventbus/action.js";
import tokenService from "./../services/token.service.js";
import ConfirmationDialog from "../components/ConfirmationDialog";
import moment from "moment";
export default {
  data() {
    return {
      table: null,
      items: [],
      options: [
        { text: "Stock No.", value: "stock_number" },
        { text: "VIN", value: "vin" },
        { text: "Make", value: "make" },
        { text: "Model", value: "model" },
        { text: "Year", value: "year" },
      ],
      dialogDelete: false,
    };
  },
  components: {
    ConfirmationDialog,
  },
  mounted() {
    this.getCarDataList();
    this.addListeners();
  },
  methods: {
    async addListeners() {
      const self = this;
      $("#example").on("click", ".edit-icon", function (e) {
        let id = e.currentTarget.dataset.secret;
        self.$router.push({ name: "Edit Vehicle", params: { id: Number(id) } });
        e.preventDefault();
      });
    },

    reload() {
      try {
        this.$store.commit("setLoader", true);
        this.table.ajax
          .url(process.env.VUE_APP_API_HOST + CARLIST)
          .load();
        $.fn.dataTable.ext.errMode = "throw";
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async getCarDataList() {
      let self = this;
      try {
        this.$store.commit("setLoader", true);
        this.table = $("#example").DataTable({
          ajax: {
            url: process.env.VUE_APP_API_HOST + CARLIST,
            type: "GET",
            beforeSend: function (request) {
              request.setRequestHeader(
                "Authorization",
                `Bearer ${tokenService.getAccessToken()}`
              );
            },
          },
          drawCallback: function (settings) {
            $(".total-car").html(
              `Total Car <span>${settings.json.recordsTotal}</span>`
            );
          },
          columns: [
            {
              orderable: false,
              data: function (full) {
                return full.id
                  ? `<span class="collespe-wrap">${full.id}</span>`
                  : "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.created_at
                  ? `<span class="description" title=${full.created_at}>${self.$options.filters.DateZoneFilter(moment(full.created_at))}</span>`
                  : "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return (
                  full.make + "/ " + full.model + "/ " + full.year || "N/A"
                );
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.vin.toUpperCase() || "N/A";
              },
            },
            {
              data: function (row) {
                return (
                  "$ " +
                  Number(row.purchase_price)
                    .toFixed(2)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                );
              },
              orderable: false,
            },
            {
              data: function (row) {
                return (
                  "$ " +
                  Number(row.parts_total_price)
                    .toFixed(2)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                );
              },
              orderable: false,
            },
            {
              data: function (row) {
                return row.inventory_checkout_car_parts_count + ' / ' + row.inventory_car_parts_count;
              },
              orderable: false,
            },
            {
              orderable: false,
              data: function (full) {
                return full.stock_number || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.notes
                  ? `<span class="description" title=${full.notes}>${full.notes}</span>`
                  : "N/A";
              },
            },
            {
              orderable: false,
              data: function (row) {
                return `
              <span class="edit-btn">
                    <router-link
                      data-secret = ${row.id}
                      class="edit-icon tooltips"
                      data-tooltip="Edit Vehicle"
                      :to="{ name: 'Edit Vehicle', params: { id: item.id } }"
                      style="padding: 8px;color: rgb(91 192 222);"
                    >
                      
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.57012 3.88944L10.1106 6.42989M9.20334 1.6514L2.33082 8.52392C1.97572 8.87853 1.73354 9.33032 1.63481 9.82235L1 13L4.17766 12.364C4.66966 12.2656 5.12087 12.0244 5.47608 11.6692L12.3486 4.79666C12.5551 4.59014 12.7189 4.34496 12.8307 4.07513C12.9425 3.8053 13 3.51609 13 3.22403C13 2.93197 12.9425 2.64276 12.8307 2.37293C12.7189 2.1031 12.5551 1.85792 12.3486 1.6514C12.1421 1.44488 11.8969 1.28106 11.6271 1.16929C11.3572 1.05753 11.068 1 10.776 1C10.4839 1 10.1947 1.05753 9.92487 1.16929C9.65504 1.28106 9.40986 1.44488 9.20334 1.6514Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
</svg>

                    </router-link></span>
                  `;
              },
            },
          ],
          pagingType: "simple_numbers",
          pageLength: ITEMS_PER_PAGE,
          serverSide: true,
          processing: false,
          dom: "Bfrtip",
          buttons: ["copy", "csv", "print"],
          language: {
            search: '',
            searchPlaceholder: "Search..."
          },
        });
        $.fn.dataTable.ext.errMode = "throw";
        // Move the search input to the custom input
        const searchInput = $(this.table.table().container()).find('.dataTables_filter input');
        $(this.$refs.dataTableSearch).replaceWith(searchInput);
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
  },
};
</script>
